import React, { useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import {Colors} from "~/constants/Colors";
import { PrimaryText } from "~/components/Common/PrimaryText";
import { useCompany } from "context/CompanyContext";
import { updatePaymentMethod, getPaymentCardInfo } from "services/subscription-service";
import { FullModal } from "components/Modals/FullModal";
import BraintreeDropIn from "screens/Expired/components/BraintreeDropIn";
import { ModalHeader } from "components/Modals/ModalHeader";

export function Subscription({setShowConfirmModal}) {

    const {company, upcomingInvoice, paymentInfo, setPaymentInfo} = useCompany()

    const [showUpdatePayment, setShowUpdatePayment] = useState(false)

    const onUpdatePayment = (cardNonce) =>{
        updatePaymentMethod(company, cardNonce, onSuccess)
    }

    const onSuccess = () => {
        getPaymentCardInfo(company, setPaymentInfo)
        setShowUpdatePayment(false)
    }

    let paymentTerm = 'monthly'
    if (company?.yearly) {
        paymentTerm = 'yearly'
    }

    return (

                
        <View style={styles.container}>
            

            <View style={styles.settingContainer}>

                <PrimaryText fontSize={20} fontWeight={500} style={styles.title} >Subscription & Payment Info:</PrimaryText>

                {/* <View style={styles.settingsRow}>
                    <PrimaryText fontSize={16} fontWeight={500} style={styles.setting} >Subscription Type:</PrimaryText>
                    <PrimaryText fontSize={16} fontWeight={300} style={styles.info} >{'Monthly'}</PrimaryText>
                </View> */}

                {!!(paymentInfo && upcomingInvoice) &&
                    <>
                        <View style={styles.settingsRow}>
                            <PrimaryText fontSize={16} fontWeight={500} style={styles.setting} >Payment Term:</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={styles.info} >{paymentTerm}</PrimaryText>
                        </View>

                        <View style={styles.settingsRow}>
                            <PrimaryText fontSize={16} fontWeight={500} style={styles.setting} >Payment Method:</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={{fontSize: 16}} >{`${paymentInfo.brand} xxxx-${paymentInfo.last4} exp: ${paymentInfo.expMonth}/${paymentInfo.expYear}`}</PrimaryText>
                            <TouchableOpacity onPress={() => setShowUpdatePayment(true)} style={styles.updateContainer}>
                                <PrimaryText fontSize={16} fontWeight={300} style={styles.updateText} >Update</PrimaryText>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.settingsRow}>
                            <PrimaryText fontSize={16} fontWeight={500} style={styles.setting} >Next Billing Cycle:</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={styles.info} >{new Date(upcomingInvoice.date.date).toDateString()}</PrimaryText>
                        </View>

                        <View style={styles.settingsRow}>
                            <PrimaryText fontSize={16} fontWeight={500} style={styles.setting} >Amount Due At Next Billing Date:</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={styles.info} >${upcomingInvoice.amountDue}</PrimaryText>
                        </View>

                        <View style={styles.settingsRow}>
                            <PrimaryText fontSize={16} fontWeight={500} style={styles.setting} >Billing Email:</PrimaryText>
                            <PrimaryText fontSize={16} fontWeight={300} style={{fontSize: 16}} >{paymentInfo.email}</PrimaryText>
                            {/* <TouchableOpacity onPress={() => setShowUpdatePayment(true)} style={styles.updateContainer}>
                                <PrimaryText fontSize={16} fontWeight={300} style={styles.updateText} >Update</PrimaryText>
                            </TouchableOpacity> */}
                        </View>

                        <TouchableOpacity 
                        style={styles.button}
                        onPress={() => setShowConfirmModal(true)}
                        >
                            <PrimaryText fontSize={14} fontWeight={300} style={{color: Colors.primary.white}}>CANCEL SUBSCRIPTION</PrimaryText>
                        </TouchableOpacity>
                    </>
                }
                
            </View>

                {showUpdatePayment &&
                    <FullModal>
                        <ModalHeader
                            title={'Update Payment Method'}
                            onClose={() => setShowUpdatePayment(false)}
                        />
                        <BraintreeDropIn
                            show={true}
                            onSubmit={(cardNonce) => onUpdatePayment(cardNonce)}
                            buttonLabel={'Update Payment Method'}
                        />
                    </FullModal>
                }
            
            
        </View>
    );
}

const styles = StyleSheet.create({

    container: {
        width: '100%',
        padding: 24,
        // backgroundColor:'red'
    },
    title: {
        color: Colors.primary.gray,
        marginVertical: 12,
        fontSize: 20
    },
    settingContainer: {
        marginBottom: 30,
    },
    settingsRow: {
        flexDirection:'row', 
        marginHorizontal: 12,
        marginVertical: 6,
        alignItems: 'center',
    },

    setting: {
        marginRight: 12,
        // width: 204,
        fontSize: 16
    },
    info: {
        textTransform: 'capitalize',
        fontSize: 16
    },
    button: {
        backgroundColor: Colors.primary.red,
        width: 200,
        justifyContent:'center',
        alignItems:'center',
        padding: 8,
        margin: 12,
        borderRadius: 6,
      },
      updateContainer: {
          marginHorizontal: 12,
          marginBottom: 2,
          justifyContent:'center',
          borderColor: Colors.primary.lightGray,
          borderWidth: 1,
          borderRadius: 4,
          paddingHorizontal: 4
        //   backgroundColor:'red'
      },
      updateText: {
          color: Colors.primary.blue
      }


});
