import React, { useEffect, useState } from "react";
import { StyleSheet, View, ActivityIndicator, FlatList, TouchableOpacity, Dimensions, ScrollView, Switch  } from "react-native";
import Select from 'react-select';
import { Colors } from "constants/Colors";
import { Header } from "components/Header";
import { CustomInput } from "components/Inputs/CustomInput";
import { useCompany } from "context/CompanyContext";
import { PrimaryText } from "components/Common/PrimaryText";
import * as FaIcons from "react-icons/fa";
import { Fonts } from "constants/Fonts";
import { cloneDeep } from "lodash";
import { ConfirmModal } from "components/Modals/Confirmation";
import { EditUserCard } from "components/Cards/EditUserCard";
import { createUser, getUserEmail, updateUserEmail, updateUserInfo } from "services/user-service";
import { FullModal } from "components/Modals/FullModal";
import { ModalHeader } from "components/Modals/ModalHeader";
import { Box } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getLocationOption, getMaxUserCountForSubscription, getNewPriceForSubscription, validateEmail } from "utils/helpers";
import { sendPasswordReset } from "services/auth-service";
import { getPaymentCardInfo, getUpcomingInvoice, updateSubscription } from "services/subscription-service";

const {height} = Dimensions.get('window')

const options = [
  { value: 'active', label: 'Active' },
  { value: 'archived', label: 'Archived' }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function Users() {

  const {company, users, locations, activeUsers, setUpcomingInvoice, setPaymentInfo, isDTools} = useCompany()

  const [value, setValue] = useState(0);
  const [search, setSearch] = useState(null)
  const [selectedView, setSelectedView] = useState(options[0])
  const [ascending, setAscending] = useState(true)
  const [filtered, setFiltered] = useState(users)
  const [sorted, setSorted] = useState(null)

  const [showEditAddUser, setShowEditAddUser] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedUserEmail, setSelectedUserEmail] = useState(null)
  const [locationOptions, setLocationOptions] = useState(null)

  const [emailError, setEmailError] = useState(null)
  const [password, setPassword] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState(null)

  const [showConfirmAddUserModal, setShowConfirmAddUserModal] = useState(false)
  const [newPrice, setNewPrice] = useState(null)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const temp = [{
      value: '',
      label: ''
    }]
    locations.forEach(location => {
      temp.push({
        value: location.id,
        label: location.name
      })
    })
    setLocationOptions(temp)
  }, [locations])

  //on search change
  useEffect(() => {
    if (search) {
      const temp = users
      .filter(x => 
        x.firstName.toLowerCase().includes(search.toLowerCase())
        || x.lastName.toLowerCase().includes(search.toLowerCase())
      )
      
      setFiltered(temp)
    } else {
      setFiltered(users)
    }
  }, [search, users])

  useEffect(() => {
    if (filtered) {

      let temp = cloneDeep(filtered)

      switch (selectedView.value) {
        case 'active':
          temp = temp.filter(x => x.active)
          break;
        case 'archived':
          temp = temp.filter(x => !x.active)
          break;
        default:
          break;
      }

      temp.sort((a,b) => sortObject(a.firstName, b.firstName))

      setSorted(temp)
    }
  }, [selectedView, ascending, filtered])

  useEffect(() => {
    if (!showEditAddUser && !showConfirmModal) {
      setSelectedUser(null) 
      setSelectedUserEmail(null)
      setLoading(false)
    } 
  }, [showEditAddUser, showConfirmModal])


  function sortObject(a, b) {

    if (a === b) return 0

    if (!ascending) {
      return a < b ? 1 : -1
    } else {
      return a < b ? -1 : 1
    }
  }

  function onArchiveUser(user) {
    setSelectedUser(user)
    if (user.active) { //archiving
      setShowConfirmModal(true)
    } else {
      //un-archiving so need to check user count
      const currentUserCount = activeUsers.length
      const maxUserCount = getMaxUserCountForSubscription(currentUserCount)
      if (currentUserCount >= maxUserCount && !isDTools) {
        setNewPrice(getNewPriceForSubscription(currentUserCount+1))
        setShowConfirmAddUserModal('unarchive')
      } else {
        setShowConfirmModal(true)
      }
    }
    
  }

  function archive() {
    setLoading(true)
    const user = {
      id: selectedUser.id,
      active: !selectedUser.active
    }
    updateUserInfo(company, user, user.active ? activeUsers.length+1 : activeUsers.length-1, onSuccessUserCountChange)
  }

  /** START ADD USER */
  function onAddUserPressed() {
    //if we need to alert price change
    // const currentUserCount = activeUsers.length
    // const maxUserCount = getMaxUserCountForSubscription(currentUserCount)
    // if (currentUserCount >= maxUserCount && !isDTools) {
    //   setNewPrice(getNewPriceForSubscription(currentUserCount+1))
    //   setShowConfirmAddUserModal('add')
    // } else {
      setShowEditAddUser('add')
    // }
  }

  function addUser(){
    setLoading(true)
    createUser(company, selectedUser, password, activeUsers.length, onSuccessUserCountChange, setEmailError)
  }

  function editUser(user) {
    setSelectedUser(user)
    setShowEditAddUser('edit')
  }

  function onChangeUserInfo(prop, value) {
    const temp = selectedUser ? cloneDeep(selectedUser) : {active: true, admin: false, firstName: '', lastName: '', requests: false, vehicle: ''}
    temp[prop] = value
    setSelectedUser(temp)
  }

  useEffect(() => {
    setEmailError(null)
    function onSuccess(email) {
      setSelectedUserEmail(email)
      onChangeUserInfo('email', email)
    }
    (selectedUser && selectedUserEmail===null && showEditAddUser==='edit') && getUserEmail(selectedUser.id, onSuccess)

    if (showEditAddUser==='edit') {
      setSubmitDisabled(
        !selectedUser
        || !selectedUser?.firstName
        || !selectedUser?.lastName
        || !selectedUser?.email
        || !validateEmail(selectedUser?.email)
      )
    } else {
      //adding user
      setSubmitDisabled(
        !password || !confirmPassword
        || password!==confirmPassword
        || password?.length<6
        || !selectedUser
        || !selectedUser?.firstName
        || !selectedUser?.lastName
        || !selectedUser?.email
        || !validateEmail(selectedUser?.email)
      )
    }

  }, [selectedUser, password, confirmPassword])

  function saveUser(){
    function onSuccess() {
      updateUserInfo(company, selectedUser, null, onSuccessUpdate)
    }
    setLoading(true)
    if (selectedUser.email !== selectedUserEmail) {
      //email changed
        updateUserEmail(company, selectedUser, selectedUserEmail, onSuccess, setEmailError)
    } else {
      onSuccess()
    }
    
  }

  function onSuccessUpdate() {
    setShowEditAddUser(false)
    setShowConfirmModal(false)
    setLoading(false)
    setSelectedUser(null)
    setSelectedUserEmail(null)
  }

  function onSuccessUserCountChange(count) {
    setShowEditAddUser(false)
    setShowConfirmModal(false)
    setLoading(false)
    setSelectedUser(null)
    setSelectedUserEmail(null)
    // if (company.paymentSubscriptionId && !isDTools) {
    //   updateSubscription(company, count, onSuccessUpdateSubscription)
      // console.log('active user count', count)
    // }
  }

  function onSuccessUpdateSubscription() {
    getUpcomingInvoice(company, setUpcomingInvoice)
    getPaymentCardInfo(company, setPaymentInfo)
  }

  function passwordResetConfirmed() {
    function onSuccess() {
      setShowPasswordConfirm(false)
    }
    sendPasswordReset(selectedUserEmail, onSuccess)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid lightgray',
      color: state.isSelected ? Colors.primary.white : Colors.primary.darkGray,
      paddingHorizontal: 12,
      fontFamily: Fonts.primary.regular,
      fontSize: 14
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 115,
    }),
    singleValue: (styles) => ({ 
      ...styles, 
      color: Colors.primary.darkGray,
      fontFamily: Fonts.primary.regular,
      fontSize: 14
    }),
  }

  return (
    <View style={styles.container}>
      <Header 
      title={'Users'} 
      // onBack={() => navigation.goBack()}
      />
      <View style={{zIndex: 999, flexDirection: 'row', width: '100%', alignItems:'center'}}>
        <TouchableOpacity 
          style={styles.button}
          onPress={() => onAddUserPressed()}
          >
            <PrimaryText fontSize={14} fontWeight={300} style={{color: Colors.primary.white, marginRight: 12}}>Add User</PrimaryText>
            <FaIcons.FaPlus size={16} color={Colors.primary.white}/>
        </TouchableOpacity>
          <CustomInput 
          placeholder={'Filter By Name'}
          style={{flex: 1, marginHorizontal: 12, height: 40}}
          onChange={setSearch}
          value={search}
          />
          <View style={{flexDirection: 'row', paddingLeft: 40, alignItems:'center'}}>
            <PrimaryText fontSize={14} fontWeight={400} style={{color: Colors.primary.darkGray, marginHorizontal: 4}} >View:</PrimaryText>
            <Select
            styles={customStyles}
            value={selectedView}
            onChange={(value) => setSelectedView(value)}
            options={options}
            />
            <TouchableOpacity onPress={() => setAscending(!ascending)} style={{marginLeft: 12}}>
              {ascending ?
              <FaIcons.FaChevronUp size={16} color={Colors.primary.darkGray}/>
              :
              <FaIcons.FaChevronDown size={16} color={Colors.primary.darkGray}/>
              }
            </TouchableOpacity>
          
          </View>
      </View>

        
      <View style={styles.body}>
        <View style={styles.detailLabelContainer}>
          {/* <PrimaryText fontSize={14} fontWeight={400} style={{width: 40}} ></PrimaryText> */}
          <PrimaryText fontSize={14} fontWeight={400} style={{flex: 1, color: Colors.primary.white, marginRight: 12}} >Name</PrimaryText>
          <PrimaryText fontSize={14} fontWeight={400} style={{flex: 1, color: Colors.primary.white}} >Assigned Location</PrimaryText>
          <View style={{flexDirection:'row'}}>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >Admin</PrimaryText>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >Requests</PrimaryText>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >Edit</PrimaryText>
              <PrimaryText fontSize={14} fontWeight={400} style={styles.detailLabel} >{selectedView.value==='active' ? 'Archive' : 'Unarchive'}</PrimaryText>
          </View>
        </View>

        <FlatList
        style={{flex: 1, width: '100%'}}
        // contentContainerStyle={{width: '100%'}}
        data={sorted}
        keyExtractor={item => item.id}
        renderItem={({ item, index }) => {
            return (
                <EditUserCard
                item={item}
                onArchive={() => onArchiveUser(item)}
                onEdit={() => editUser(item)}
                location={locations.find(x => x.id===item.vehicle)}
                />
            )
        }}
        />

      </View>

      {/** EDIT USER */}
      {showEditAddUser &&
        <FullModal>
          <ScrollView style={styles.modalContent}>
            <ModalHeader
            title={showEditAddUser==='edit' ? 'Edit User' : 'Add User'}
            onClose={() => setShowEditAddUser(false)}
            />
              <Box sx={{ width: '100%', maxWidth: 500 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Settings" {...a11yProps(0)} />
                    <Tab label="Permissions" {...a11yProps(1)} />
                    <Tab label="Notifications" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                {/** SETTINGS */}
                <TabPanel value={value} index={0}>
                    <CustomInput
                    title={'First Name'}
                    value={selectedUser?.firstName}
                    onChange={(text) => onChangeUserInfo('firstName', text)}
                    style={{width: 400}}
                    /><br></br>
                    <CustomInput
                    title={'Last Name'}
                    value={selectedUser?.lastName}
                    onChange={(text) => onChangeUserInfo('lastName', text)}
                    style={{width: 400}}
                    /><br></br>
                    <CustomInput
                    title={'Email'}
                    value={selectedUser?.email}
                    onChange={(text) => onChangeUserInfo('email', text.toLowerCase())}
                    style={{width: 400}}
                    errorMessage={!validateEmail(selectedUser?.email) ? 'Invalid email' : emailError ? emailError : null}
                    /><br></br>
                    {showEditAddUser==='edit' ?
                      <TouchableOpacity 
                      style={{alignItems:'center', padding: 3, margin: 8}}
                      onPress={() => setShowPasswordConfirm(true)}
                      >
                        <PrimaryText fontSize={14} fontWeight={300} style={{color: Colors.primary.blue}}>{'Send Password Reset Email'}</PrimaryText>
                      </TouchableOpacity>
                      :
                      <>
                        <CustomInput
                        title={'Password'}
                        value={password}
                        onChange={(text) => setPassword(text)}
                        style={{width: 400}}
                        secure={true}
                        />
                        <CustomInput
                        title={'Confirm Password'}
                        value={confirmPassword}
                        onChange={(text) => setConfirmPassword(text)}
                        style={{width: 400}}
                        errorMessage={(password!==confirmPassword && confirmPassword?.length>=password?.length) ? 'Passwords do not match' : null}
                        secure={true}
                        />
                      </>
                    }

                    <View style={{flexDirection: 'column', marginTop: 12, marginBottom: 24, alignItems:'flex-start', zIndex: 999}}>
                      <PrimaryText fontSize={14} fontWeight={700} style={styles.setting} >Assigned Location</PrimaryText>
                      <Select
                      styles={customStyles}
                      value={selectedUser?.vehicle && getLocationOption(locations, selectedUser?.vehicle)}
                      onChange={(value) => onChangeUserInfo('vehicle', value.value)}
                      options={locationOptions}
                      />
                      
                    </View>
                    
                    <View style={styles.settingsRow} >
                      <Switch
                      value={selectedUser?.admin}
                      onValueChange={(value) => onChangeUserInfo('admin', value)}
                      />
                      <PrimaryText fontSize={14} fontWeight={700} style={styles.setting} >Admin</PrimaryText>
                    </View>
                    <View style={styles.settingsRow} >
                      <Switch
                      value={selectedUser?.useVehicleAsDefault}
                      onValueChange={(value) => onChangeUserInfo('useVehicleAsDefault', value)}
                      />
                      <PrimaryText fontSize={14} fontWeight={700} style={styles.setting} >Use Assigned Location As Default Location In App</PrimaryText>
                    </View>

                    
                  
                </TabPanel>
                {/** PERMISSIONS */}
                <TabPanel value={value} index={1}>
                  <View style={styles.settingsRow} >
                    <Switch
                    value={selectedUser?.viewItemCost!==undefined ? selectedUser?.viewItemCost : true}
                    onValueChange={(value) => onChangeUserInfo('viewItemCost', value)}
                    />
                    <PrimaryText fontSize={14} fontWeight={700} style={styles.setting} >View Item Cost Amount</PrimaryText>
                  </View>
                  <View style={styles.settingsRow} >
                      <Switch
                      value={selectedUser?.viewItemPrice!==undefined ? selectedUser?.viewItemPrice : true}
                      onValueChange={(value) => onChangeUserInfo('viewItemPrice', value)}
                      />
                      <PrimaryText fontSize={14} fontWeight={700} style={styles.setting} >View Item Sell Amount</PrimaryText>
                      
                  </View>
                  <View style={styles.settingsRow} >
                    <Switch
                    value={selectedUser?.viewHours!==undefined ? selectedUser?.viewHours : true}
                    onValueChange={(value) => onChangeUserInfo('viewHours', value)}
                    />
                    <PrimaryText fontSize={14} fontWeight={700} style={styles.setting} >View Budget vs. Actual Hours</PrimaryText>
                      
                  </View>
                </TabPanel>
                {/** NOTIFICATIONS */}
                <TabPanel value={value} index={2}>
                  <View style={styles.settingsRow} >
                    <Switch
                    value={selectedUser?.requests}
                    onValueChange={(value) => onChangeUserInfo('requests', value)}
                    />
                    <PrimaryText fontSize={14} fontWeight={700} style={styles.setting} >Receive Request Notifications</PrimaryText>
                  </View>
                  <View style={styles.settingsRow} >
                    <Switch
                    value={selectedUser?.receiveMinimumStockNotifications}
                    onValueChange={(value) => onChangeUserInfo('receiveMinimumStockNotifications', value)}
                    />
                    <PrimaryText fontSize={14} fontWeight={700} style={styles.setting} >Receive Min/Max Stock Alerts</PrimaryText>
                  </View>
                </TabPanel>
              </Box>
              <TouchableOpacity 
              style={[styles.button2, submitDisabled && {opacity:0.4}]}
              onPress={() => showEditAddUser==='edit' ? saveUser() : addUser()}
              disabled={submitDisabled}
              >
                <PrimaryText fontSize={14} fontWeight={300} style={{color: Colors.primary.white}}>{`${showEditAddUser==='edit' ? 'Save' : 'Add User'}`}</PrimaryText>
              </TouchableOpacity>
            {/* <PrimaryText fontSize={16} fontWeight={500} >New Location Name</PrimaryText> */}
          </ScrollView>
        </FullModal>
      }

      {!!showConfirmAddUserModal &&
        <ConfirmModal
        title={`User limit exceeded for your current subscription. Your new subscription price will be $${newPrice} per month`}
        message={'Are you sure?'}
        onConfirm={() => {
          let test = showConfirmAddUserModal==='unarchive'
          setShowConfirmModal(false)
          setShowConfirmAddUserModal(false)
          test ? archive() : setShowEditAddUser('add')
        }}
        onCancel={() => setShowConfirmAddUserModal(false)}
        />
      }

      {showConfirmModal &&
        <ConfirmModal
        title={`${selectedUser?.active ? 'Archive' : 'Unarchive'} ${selectedUser?.firstName+ ' '+ selectedUser?.lastName}`}
        message={'Are you sure?'}
        onConfirm={() => archive()}
        onCancel={() => setShowConfirmModal(false)}
        />
      }

      {showPasswordConfirm &&
        <ConfirmModal
        title={'Send Password Reset Email'}
        message={''}
        onConfirm={() => passwordResetConfirmed()}
        onCancel={() => setShowPasswordConfirm(false)}
        />
      }
      

      {loading &&
        <ActivityIndicator 
        animating={loading}
        size={'large'} 
        style={StyleSheet.absoluteFill} color={Colors.primary.blue} 
        />
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
      flex: 1,
      width: '100%',
      flexDirection: "column",
      backgroundColor: Colors.primary.white,
  },
  button: {
    flexDirection: 'row',
    backgroundColor: Colors.primary.blue,
    width: 200,
    justifyContent:'center',
    alignItems:'center',
    padding: 8,
    margin: 12,
    borderRadius: 6,
  },
  button2: {
    flexDirection: 'row',
    backgroundColor: Colors.primary.green,
    width: 100,
    justifyContent:'center',
    alignItems:'center',
    padding: 8,
    margin: 12,
    borderRadius: 6,
  },
  body: {
      flex: 1,
      width: '100%',
      backgroundColor: Colors.primary.white,
      marginTop: 12,
      alignItems:'center'
  },

  //details
  detailLabelContainer: {
    width: '100%',
    flexDirection:'row',
    justifyContent:'space-between',
    paddingHorizontal: 12,
    marginTop: 12,
    backgroundColor: Colors.primary.gray
  },
  detailLabel: {
      // marginHorizontal: 6,
      width: 70,
      textAlign:'center',
      color: Colors.primary.white
  },
  modalContent: {
    height: height*0.8,
    minWidth: 500,
    backgroundColor: Colors.primary.white
  },
  settingsRow: {
    flexDirection:'row', 
    margin: 12,
    alignItems: 'center'
  },
  setting: {
    marginLeft: 18,
  },


});
