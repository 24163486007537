const baseURL = 'https://api.tracknicity.com'
// const baseURL = 'http://localhost:8080'

export const sendSupportEmail = (company, currentUser, email, info, func) => {
    const url = baseURL+'/support'

    const {firstName, lastName} = currentUser
  
    const body =  {
      companyDocId: company.id,
      companyName: company.name,
      firstName,
      lastName,
      email,
      info
    }
  
    const data = new FormData()
    for ( var key in body ) {
      data.append(key, body[key]);
    }
  
    const requestOptions = {
      method: 'POST',
      body: data
    };
  
    fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
        //   if (data.success) {
            func()
        //   }
          console.log('sendSupportEmail', data)
          
        })
        .catch(e => {
          console.log('error sendSupportEmail', e)
        })
}

export const sendCustomerOptIn = (company, email, signupDate, userCount, isDTools, yearly) => {
  const url = baseURL+'/customerOptIn'

  const body =  {
    companyDocId: company.id,
    companyName: company.name,
    email,
    signupDate,
    userCount,
    isDTools: false,
    yearly
  }

  const data = new FormData()
  for ( var key in body ) {
    data.append(key, body[key]);
  }

  const requestOptions = {
    method: 'POST',
    body: data
  };

  fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log('sendCustomerOptIn', data)
      })
      .catch(e => {
        // console.log('error sendCustomerOptIn', e)
      })
}